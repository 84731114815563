<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Formularios</h4>
            <div class="small text-muted">Administración de formularios</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Formularios">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    v-if="table.items.length">

                <template v-slot:cell(id)="data">
                  <b>{{ data.item.id }}</b>
                </template>      

                <template v-slot:cell(receiver)="data">
                  <div v-if="data.item.email_receiver">
                    <div v-for="value in JSON.parse(data.item.email_receiver)" :key="value">
                      <b-icon icon="envelope-fill"></b-icon> {{ value }}
                    </div>
                  </div>
                </template>      

                <template v-slot:cell(fields)="data">
                  <div v-if="data.item.fields">
                    <b-badge variant="secondary" 
                             class="mr-1" 
                             v-for="(element, key) in JSON.parse(data.item.fields)" 
                             :key="key">
                      {{element.name}}
                    </b-badge>
                  </div>
                  <div v-else>
                    <p class="text-danger mb-0">
                      <b>No se configuró el formulario</b>
                    </p>
                  </div>
                </template> 

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="configFieldsForm(data.item)">
                      <b-icon icon="menu-button-wide-fill"></b-icon> Campos
                    </b-dropdown-item>                                                      
                    <b-dropdown-header>Acciones</b-dropdown-header> 
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Receptores Notificaciones">
              <b-form-tags                
                v-model="crud.form.email_receiver"
                separator=" ,"
                placeholder="Ingrese nuevos correos separadas por enter, espacio o coma"
              ></b-form-tags>            
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Columnas">
              <b-form-select v-model="crud.form.cols" :options="arr.form.column" size="sm"></b-form-select>              
            </b-form-group>
          </b-col>

        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD CONFIG -->
      <b-modal v-model="modal.config.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.config.title}}
        </div>

        <b-row>   
          <b-col lg="7">
            <b-row>   
              <b-col lg="5" class="pr-0">
                <b-form-group label="Título">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.config.fieldsTMP.title"
                                placeholder="Título del campo">
                  </b-form-input>
                </b-form-group>
              </b-col>                          
              <b-col lg="4" class="pr-0">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.config.fieldsTMP.name"
                                placeholder="Nombre del campo">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3" class="pr-0">
                <b-form-group label="Tipo">
                  <b-form-select v-model="crud.config.fieldsTMP.type" :options="arr.typeFields" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="4">   
            <b-row>
              <b-col lg="6" class="pr-0">
                <b-form-group label="Columna">
                  <b-form-select v-model="crud.config.fieldsTMP.column" :options="arr.column" size="sm"></b-form-select>              
                </b-form-group>
              </b-col>  
              <b-col lg="6" class="pr-0">
                <b-form-group label="Grid">
                  <b-form-select v-model="crud.config.fieldsTMP.cols" :options="arr.cols" size="sm"></b-form-select>              
                </b-form-group>
              </b-col>                   
            </b-row>
          </b-col>        
          <b-col lg="1" class="forms-config-fields">
            <b-button type="button" 
                      variant="outline-dark" 
                      class="pull-right" 
                      size="sm" 
                      @click="addField()"
                      v-b-tooltip.hover
                      title="Guardar Campo">                            
              <i v-if="crud.config.fieldsTMP.edit" class="fa fa-save"></i>
              <i v-else class="fa fa-plus"></i>
            </b-button>             
          </b-col>
          <b-col lg="12" v-if="crud.config.fieldsTMP.type=='select'">
            <b-form-input type="text"
                          size="sm"
                          v-model="crud.config.fieldsTMP.options"
                          placeholder="Opciones separadas por coma (,)">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12">
            
            <b-list-group v-if="crud.config.fields.length" 
                          class="forms-config-fields-details">         
              <draggable :list="crud.config.fields">
                <b-list-group-item href="#"
                                  class="flex-column align-items-start"                                                                      
                                  v-for="(value, key) in crud.config.fields"
                                  :key="key"
                                  v-b-tooltip.hover
                                  :disabled="crud.config.fieldsTMP.edit">

                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{value.name}}</h5>
                    <small>
                      <b-badge variant="secondary">{{value.type}}</b-badge>
                    </small>
                  </div>
                  
                  <b-button @click="deleteField(value)" 
                            type="button" 
                            variant="outline-danger" 
                            size="sm" 
                            class="btn-pill float-right" 
                            v-b-tooltip.hover 
                            title="Quitar Campo">
                    <i class="fa fa-trash"></i>
                  </b-button>
                  <b-button @click="editField(key)" 
                            type="button" 
                            variant="outline-info" 
                            size="sm" 
                            class="btn-pill float-right mr-1" 
                            v-b-tooltip.hover 
                            title="Editar Campo">
                    <i class="fa fa-edit"></i>                    
                  </b-button>     
  
                  <p class="mb-0">                  
                    <b>Título:</b> {{value.title}} | <b>Columna:</b> <span v-if="value.column">{{value.column}}</span><span v-else>1</span> | <b>Grid:</b> col-md-{{value.cols}}
                  </p>
                  <p class="mb-0" v-if="value.options">                  
                      <b>Opciones:</b> 
                    <b-badge v-for="(element,key) in value.options.split(',')" 
                            :key="key"
                            variant="secondary"
                            class="ml-1">
                      {{element}}
                    </b-badge>
                  </p>                  

                </b-list-group-item>  
              </draggable>            
            </b-list-group>
      
          </b-col>
        </b-row> 

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.config.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveFields()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import draggable from 'vuedraggable'

  export default {
    components: {
      draggable,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.FORMULARIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudForms',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},
            {key: 'name', label: 'Nombre', class:"align-middle"},
            {key: 'receiver', label: 'Receptores', class:"align-middle"},
            {key: 'fields', label: 'Campos', class:"align-middle"},
            {key: 'f_action', label:'', class:"align-middle"},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            name: '',
            email_receiver: [],
            cols: 1,
          },
          config: {
            id: 0,
            fields: [
              {name: 'name', type: 'string', cols: 12, options:'', title: 'Nombre'},
              {name: 'email', type: 'email', cols: 6, options:'', title: 'Email' },
              {name: 'phone', type: 'string', cols: 6, options:'', title: 'Teléfono' },
              {name: 'message', type: 'text', cols: 12, options:'', title: 'Mensaje' }
            ],
            fieldsTMP: {
              type: 'string',
              name: '',
              cols: 12,
              column: 1,
              options: '',
              title: '',
              index: 0,
              edit: false
            }            
          }    
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          config: {
            active: false,
            title: ''
          },          
        },
        arr: {
          typeFields: [
            {value: 'string', text: 'Texto'},
            {value: 'date', text: 'Fecha'},
            {value: 'email', text: 'Email'},
            {value: 'text', text: 'Texto Largo'},
            {value: 'integer', text: 'Entero'},
            {value: 'decimal', text: 'Decimal'},            
            {value: 'checkbox', text: 'Si/No'},
            {value: 'select', text: 'Opciones'},
            {value: 'file', text: 'Archivo'},            
            {value: 'hidden', text: 'Oculto'},   
          ],
          cols: [
            {value: 1, text: 'Cols 1'},
            {value: 2, text: 'Cols 2'},
            {value: 3, text: 'Cols 3'},
            {value: 4, text: 'Cols 4'},
            {value: 5, text: 'Cols 5'},
            {value: 6, text: 'Cols 6'},
            {value: 7, text: 'Cols 7'},
            {value: 8, text: 'Cols 8'},
            {value: 9, text: 'Cols 9'},
            {value: 10, text: 'Cols 10'},
            {value: 11, text: 'Cols 11'},
            {value: 12, text: 'Cols 12'},
          ],
          column: [],
          form: {
            column: [
              {value: 1, text: '1 Columna'},
              {value: 2, text: '2 Columnas'},
              {value: 3, text: '3 Columnas'},            
            ]
          }
        }     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },   
    methods: {
      getRowCount (items) {
        return items.length
      },
      load() {
        var result = serviceAPI.obtenerForms()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = '' 
        this.crud.form.email_receiver = []
        this.crud.form.cols = 1

        this.modal.form.title = "Nuevo Formulario"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name   
        this.crud.form.cols = item.cols
        
        if(item.email_receiver) {
          this.crud.form.email_receiver = JSON.parse(item.email_receiver)
        } else {
          this.crud.form.email_receiver = item.email_receiver
        }
        
        this.modal.form.title = "Editar Formulario"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Formulario',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarForms(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {        
        let loader = this.$loading.show();

        var status = true
        if(this.crud.form.email_receiver.length) {
          let arrEmail = this.crud.form.email_receiver
          arrEmail.forEach(element => {
            if(element) {
              var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
              if (!expreg.test(element)) {                          
                status = false
              }       
            }            
          });
        }
        if(!status) {
          this.$awn.alert("Unos de los emails posee un formato inválido")          
          loader.hide()
          return false
        }
        
        if (this.crud.form.id) {
          var result = serviceAPI.editarForms(this.crud.form);
        } else {
          var result = serviceAPI.agregarForms(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      configFieldsForm(item) {
        this.crud.config.id = item.id
        if(item.fields) {
          this.crud.config.fields = []
          this.crud.config.fields = JSON.parse(item.fields)
        } else {
          this.crud.config.fields = []
          this.crud.config.fields.push({name: 'name', type: 'string', cols: 12, options: '', title: 'Nombre', })
          this.crud.config.fields.push({name: 'email', type: 'email', cols: 6, options: '', title: 'Email', })
          this.crud.config.fields.push({name: 'phone', type: 'string', cols: 6, options: '', title: 'Teléfono', })
          this.crud.config.fields.push({name: 'message', type: 'text', cols: 12, options: '', title: 'Mensaje', })
        }

        this.arr.column = []
        switch (item.cols) {
          case 1:
              this.arr.column.push({value: 1, text: '1 Columna'})
            break;
        
          case 2:
              this.arr.column.push({value: 1, text: '1 Columna'})
              this.arr.column.push({value: 2, text: '2 Columnas'})
            break;

          case 3:
              this.arr.column.push({value: 1, text: '1 Columna'})
              this.arr.column.push({value: 2, text: '2 Columnas'})
              this.arr.column.push({value: 3, text: '3 Columnas'})
            break;
        }        

        this.modal.config.title = "Configurar Formulario"
        this.modal.config.active = true
      },
      addField() {
        var item = this.crud.config.fieldsTMP
        var index = this.crud.config.fieldsTMP.index
        var edit = this.crud.config.fieldsTMP.edit

        if(!item.name) {
          this.$awn.alert("No se puede agregar un campo vacío")
          return false
        }

        var valid = false
        this.crud.config.fields.forEach((element, key) => {          
          if(edit) {             
            // EDITAR
            if(element.name==item.name && key!=index) {
              valid = true
            }            
          } else {
            // AGREGAR
            if(element.name==item.name) {              
              valid = true
            }                        
          }
        });

        if(valid) {
          this.$awn.alert("No se puede agregar dos veces el mismo campo")
          this.clearFieldTMP()
          return false
        }

        if(edit) {          
          // EDITAR
          this.crud.config.fields[index] = {
            type: item.type,
            name: item.name,            
            cols: item.cols,
            column: item.column,
            options: item.options,    
            title: item.title,    
          }
          this.clearFieldTMP()
        } else {
          // AGREGAR
          this.crud.config.fields.push({
            type: item.type,
            name: item.name,            
            cols: item.cols,
            column: item.column,
            options: item.options,    
            title: item.title,    
          })
          this.clearFieldTMP()
        }        
      },
      editField(index) {        
        this.crud.config.fieldsTMP.type = this.crud.config.fields[index].type
        this.crud.config.fieldsTMP.name = this.crud.config.fields[index].name
        this.crud.config.fieldsTMP.cols = this.crud.config.fields[index].cols
        this.crud.config.fieldsTMP.column = this.crud.config.fields[index].column
        this.crud.config.fieldsTMP.options = this.crud.config.fields[index].options
        this.crud.config.fieldsTMP.title = this.crud.config.fields[index].title

        this.crud.config.fieldsTMP.index = index
        this.crud.config.fieldsTMP.edit = true
      },
      deleteField(item) {        
        var i = this.crud.config.fields.indexOf( item );

        if ( i !== -1 ) {
          this.crud.config.fields.splice( i, 1 );
        }        
      },
      saveFields() {
        let loader = this.$loading.show();

        var valid = this.validFormConfig()
        if(valid) {
          loader.hide()
          this.$awn.alert(valid);
          return false
        }

        var result = serviceAPI.editarFormsFields(this.crud.config);
        
        result.then((response) => {
          this.modal.config.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },     
      validFormConfig() {
        var statusName = false

        this.crud.config.fields.forEach(element => {
          if(element.name=='name') {
            statusName = true
          }
        });

        if(!statusName) {
          return "El campo nombre es obligatorio"
        }            

        return ''
      },
      clearFieldTMP() {
        this.crud.config.fieldsTMP.type = 'string'
        this.crud.config.fieldsTMP.name = ''
        this.crud.config.fieldsTMP.cols = 12
        this.crud.config.fieldsTMP.column = 1
        this.crud.config.fieldsTMP.options = ''
        this.crud.config.fieldsTMP.title = ''
        this.crud.config.fieldsTMP.index = 0
        this.crud.config.fieldsTMP.edit = false
      }
    }    
  }
</script>
<style>
  .forms-config-fields {
    margin-top: 30px;
  }
  .forms-config-fields-details{
    overflow: overlay;
    max-height: 350px;
  }
</style>